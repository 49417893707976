import React, { useState } from "react";
import ListBlogs from "./ListBlogs";
import axios from "axios";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import styles

const getDate = () => {
  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

const Blogs = () => {
  const [pageType, setPageType] = useState("list");
  const [blog, setBlog] = useState({
    title: "",
    content: "",
    author: "",
    // image: "",
    date_published: getDate(),
  });
  const [msg, setMsg] = useState();
  const [file, setFile] = useState();


  const handleEditorChange = (value) => {
    setBlog({
      ...blog,
      content: value,
    });
  };
  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
    // if (e.target.files) {
    //   setBlog({ ...blog, image: e.target.files[0] });
    // }
  };

  const handleFormChange = (e) => {
    setBlog({
      ...blog,
      [e.target.name]: e.target.value,
    });
  };

  const formatDateToText = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', options);
  };
  

  const saveBlog = () => {
    if (blog.title && blog.author && blog.content && file) {
      const formData = new FormData();
      formData.append("title", blog.title);
      formData.append("author", blog.author);
      formData.append("image", file);
      formData.append("content", blog.content);
      formData.append("date_published", formatDateToText(blog.date_published));

      axios
        .post("https://backend.acubedevelopers.com/blogs", formData, {
          headers: {
            Authorization: "Basic <QWN1YmU6RHViYWlEZW1v>",
          },
        })
        .then((response) => {
          console.log(response);
          setBlog({
            title: "",
            content: "",
            author: "",
            // image: "",
            date_published: "",
          });
          setMsg("success");
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setMsg("error");
    }
  };

  return (
    <>
      <div className="container mt-5 d-flex justify-content-end">
        <button
          className="btn btn-outline-secondary btn-lg"
          onClick={() => setPageType(pageType === "list" ? "blog" : "list")}
        >
          {pageType === "list" ? "Add Blog" : "Back"}
        </button>
      </div>
      {pageType === "list" ? (
        <ListBlogs />
      ) : (
        <div className="container my-5 py-5">
          <h1>Add Blog</h1>
          <div className="form-group row mb-4">
            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
              Title
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                id="title"
                name="title"
                placeholder="Title"
                value={blog.title}
                onChange={(e) => handleFormChange(e)}
              />
            </div>
          </div>
          <div className="form-group row mb-4">
            <label htmlFor="inputPassword3" className="col-sm-2 col-form-label">
              Author
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                id="author"
                placeholder="Author"
                name="author"
                value={blog.author}
                onChange={(e) => handleFormChange(e)}
              />
            </div>
          </div>
          <div className="form-group row mb-4">
            <label htmlFor="date_published" className="col-sm-2 col-form-label">
              Date Published
            </label>
            <div className="col-sm-10">
              <input
                type="date"
                className="form-control"
                id="date_published"
                name="date_published"
                value={blog.date_published}
                onChange={(e) => handleFormChange(e)}
              />
            </div>
          </div>
          <div className="form-group row mb-4">
            <label htmlFor="inputPassword3" className="col-sm-2 col-form-label">
              Blog Image
            </label>
            <div className="col-sm-10">
              <input
                type="file"
                className="form-control"
                id="image"
                placeholder="image"
                onChange={handleFileChange}
              />
            </div>
          </div>
          <div className="form-group row mb-4">
            <label htmlFor="content" className="col-sm-2 col-form-label">
              Content
            </label>
            <div className="col-sm-10">
              <ReactQuill 
                value={blog.content} 
                onChange={handleEditorChange} 
                id="content" 
                name="content"
                placeholder="Content"
                className="form-control"
              />
            </div>
          </div>
          <div className="form-group row mb-4">
            <div className="col-sm-2 d-flex justify-content-between">
              <button
                type="button"
                className="btn btn-primary btn-lg"
                onClick={saveBlog}
              >
                Save
              </button>
              {/* <button type="button" className="btn btn-outline-secondary btn-lg">
            Cancel
          </button> */}
            </div>
          </div>
          {msg ? (
            <>
              {msg === "error" ? (
                <div className="alert alert-danger mt-2" role="alert">
                  All Fields are Mandatory.
                </div>
              ) : (
                <div className="alert alert-success mt-2" role="alert">
                  Blog posted successfully!
                </div>
              )}
            </>
          ) : null}
        </div>
      )}
    </>
  );
};

export default Blogs;
