import React, { useState, useCallback } from "react";
import axios from "axios";
import logo from "../../assets/img/logo.png";

import "./styles/Login.css";

const Login = () => {
  const [login, setLogin] = useState({
    username: "",
    password: "",
  });

  const changeEventFun = (e) => {
    setLogin({
      ...login,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = useCallback(() => {
    console.log(login);
    if (login.username && login.password) {
      axios
        .post("https://backend.acubedevelopers.com/admin/login", login)
        .then((response) => {
          sessionStorage.setItem("tkn", response?.data?.Token);
          window.location.href = "/";
        })
        .catch((error) => {
          console.log(error);
          alert(error?.response?.data?.error);
        });
    } else {
      alert("Make sure username and password are not empty");
    }
  });

  return (
    <main className="form-signin w-100 m-auto text-center">
      <div>
        <img className="mb-4" src={logo} alt="" height="57" />
        <h1 className="h3 mb-3 fw-normal">Please sign in</h1>

        <div className="form-floating">
          <input
            type="text"
            name="username"
            className="form-control"
            id="floatingInput"
            placeholder="username"
            value={login.username}
            onChange={(e) => changeEventFun(e)}
          />
          <label htmlFor="floatingInput">username</label>
        </div>
        <div className="form-floating">
          <input
            type="password"
            name="password"
            className="form-control"
            id="floatingPassword"
            placeholder="Password"
            value={login.password}
            onChange={(e) => changeEventFun(e)}
          />
          <label htmlFor="floatingPassword">Password</label>
        </div>
        <button className="w-100 btn btn-lg btn-primary" onClick={onSubmit}>
          Sign in
        </button>
        <p className="mt-5 mb-3 text-muted">&copy; 2017-2022</p>
      </div>
    </main>
  );
};

export default Login;
