import React, { useEffect, useState, createContext } from "react";
import Layouts from "../../Components/Layouts";
import ListCareers from "./ListCareers";
import axios from "axios";

export const CareersContext = createContext();
const initialvalue = {
  position: "",
  description: "",
  location: "",
  type: "",
};
const Careers = () => {
  const [pageType, setPageType] = useState("list");
  const [id, setId] = useState();
  const [careers, setCareers] = useState(initialvalue);
  const [msg, setMsg] = useState("");

  useEffect(() => {
    setMsg("");
  }, []);

  const handleFormChange = (e) => {
    setCareers({
      ...careers,
      [e.target.name]: e.target.value,
    });
  };

  const saveCareers = () => {
    if (
      careers.position &&
      careers.description &&
      careers.location &&
      careers.type
    ) {
      axios({
        method: pageType === "edit" ? "put" : "post",
        url:
          pageType === "edit"
            ? `https://backend.acubedevelopers.com/careers/` + id
            : "https://backend.acubedevelopers.com/careers",
        data: careers,
        headers: {
          Authorization: "Basic <QWN1YmU6RHViYWlEZW1v>",
        },
      })
        .then((response) => {
          console.log(response);
          setCareers({
            position: "",
            description: "",
            location: "",
            type: "",
          });
          setMsg("success");
          setTimeout(() => {
            setPageType("list");
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setMsg("error");
    }
  };

  return (
    <Layouts>
      <CareersContext.Provider
        value={{ pageType, setPageType, careers, setCareers, setId }}
      >
        <div className="container mt-5 d-flex justify-content-end">
          <button
            className="btn btn-outline-secondary btn-lg"
            onClick={() => setPageType(pageType === "list" ? "career" : "list")}
          >
            {pageType === "list" ? "Add Careers" : "Back"}
          </button>
        </div>
        {pageType === "list" ? (
          <ListCareers />
        ) : (
          <div className="container my-5 py-5">
            <h1>Add Careers</h1>
            <div className="form-group row mb-4">
              <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
                Job Type
              </label>
              <div className="col-sm-10">
                <input
                  type="text"
                  className="form-control"
                  id="type"
                  name="type"
                  placeholder="Job Type"
                  value={careers.type}
                  onChange={(e) => handleFormChange(e)}
                />
              </div>
            </div>
            <div className="form-group row mb-4">
              <label
                htmlFor="inputPassword3"
                className="col-sm-2 col-form-label"
              >
                Position
              </label>
              <div className="col-sm-10">
                <input
                  type="text"
                  className="form-control"
                  id="position"
                  placeholder="Position"
                  name="position"
                  value={careers.position}
                  onChange={(e) => handleFormChange(e)}
                />
              </div>
            </div>

            <div className="form-group row mb-4">
              <label
                htmlFor="inputPassword3"
                className="col-sm-2 col-form-label"
              >
                Description
              </label>
              <div className="col-sm-10">
                <textarea
                  rows="8"
                  className="form-control"
                  id="description"
                  placeholder="Description"
                  name="description"
                  value={careers.description}
                  onChange={(e) => handleFormChange(e)}
                />
              </div>
            </div>

            <div className="form-group row mb-4">
              <label
                htmlFor="inputPassword3"
                className="col-sm-2 col-form-label"
              >
                Location
              </label>
              <div className="col-sm-10">
                <input
                  type="text"
                  className="form-control"
                  id="location"
                  placeholder="Location"
                  name="location"
                  value={careers.location}
                  onChange={(e) => handleFormChange(e)}
                />
              </div>
            </div>
            <div className="form-group row mb-4">
              <div className="col-sm-2 d-flex justify-content-between">
                <button
                  type="button"
                  className="btn btn-primary btn-lg"
                  onClick={saveCareers}
                >
                  Save
                </button>
                {/* <button type="button" className="btn btn-outline-secondary btn-lg">
            Cancel
          </button> */}
              </div>
            </div>
            {msg ? (
              <>
                {msg === "error" ? (
                  <div className="alert alert-danger mt-2" role="alert">
                    All Fields are Mandatory.
                  </div>
                ) : (
                  <div className="alert alert-success mt-2" role="alert">
                    Job posted successfully!
                  </div>
                )}
              </>
            ) : null}
          </div>
        )}
      </CareersContext.Provider>
    </Layouts>
  );
};

export default Careers;
