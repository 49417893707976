import React from "react";
import {
  Navigate,
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Login from "./Pages/Login";
import Blogs from "./Pages/Blogs";
import Subscribers from "./Pages/Subscribers";
import Careers from "./Pages/Careers";
import "./App.css";

const getToken = () => {
  return sessionStorage.getItem("tkn");
};

function App() {
  const token = getToken();

  if (!token) {
    return <Login />;
  }

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Blogs />}></Route>
        <Route path="*" element={<Navigate to="/" replace={true} />} />
        <Route exact path="/subscribers" element={<Subscribers />}></Route>
        <Route exact path="/careers" element={<Careers />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
