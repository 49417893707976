import React, { useEffect, useState } from "react";
import axios from "axios";

const ListBlogs = () => {
  let [responseData, setResponseData] = useState([]);

  const fetchData = () => {
    axios({
      method: "GET",
      url: "https://backend.acubedevelopers.com/blogs",
      headers: {
        Authorization: "Basic <QWN1YmU6RHViYWlEZW1v>",
      },
    })
      .then((response) => {
        console.log(response);
        setResponseData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="container py-5">
      <h1>Blogs List</h1>
      {responseData.map((ele) => {
        return (
          <section className="py-5" key={ele.id}>
            <div className="container px-4 px-lg-5 my-5">
              <div className="row gx-4 gx-lg-5 align-items-center">
                <div className="col-md-6">
                  <img
                    className="card-img-top mb-5 mb-md-0"
                    src={ele.image_filename}
                    alt="..."
                  />
                </div>
                <div className="col-md-6">
                  <div className="small mb-1">
                    Date Published: {ele.date_published}
                  </div>
                  <h1 className="display-5 fw-bolder">{ele.title}</h1>
                  <div className="fs-5 mb-5">
                    <span>Author: {ele.author}</span>
                  </div>
                  <p className="lead">{ele.content}</p>
                </div>
              </div>
            </div>
          </section>
        );
      })}
    </div>
  );
};

export default ListBlogs;
