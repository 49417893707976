import React, { useEffect, useState } from "react";
import Layouts from "../../Components/Layouts";
import axios from "axios";

const Subscribers = () => {
  let [responseData, setResponseData] = useState([]);

  const fetchData = () => {
    axios({
      method: "GET",
      url: "https://backend.acubedevelopers.com/subscribers",
      headers: {
        Authorization: "Basic <QWN1YmU6RHViYWlEZW1v>",
      },
    })
      .then((response) => {
        console.log(response);
        setResponseData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Layouts>
      <div className="container my-5 py-5">
        <h1>Subscribers List</h1>
        <table className="table mt-4">
          <thead>
            <tr>
              <th scope="col">id</th>
              <th scope="col">Email Id</th>
            </tr>
          </thead>
          <tbody>
            {responseData.map((ele) => {
              return (
                <tr key={ele.id}>
                  <th scope="row">{ele.id}</th>
                  <td>{ele.email}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Layouts>
  );
};

export default Subscribers;
